<template>
	<div class="page">
		<div class="title">
			<div class="titleL">充值订单详情</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<!-- <div class="tit">
			<div class="titL">
				<span class="titText">会员资料</span>
				<img class="editImg" src="../../../assets/img/shouyin/edit.png" @click="dialogEditUser_state=true"/>
			</div>
			<div class="titR">
				<el-button type="infor" @click="$router.push({path:'/huiyuan/huiyuanOrderSee?id='+orderDetail.id})">订单记录</el-button>
			</div>
		</div> -->
		<!-- 会员资料 -->
		<div class="msgBox">
			<el-row>
				<el-col class="msgItem" :span="6">
					订单号：{{orderDetail.orderNo}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					顾客：{{orderDetail.userInfo.userName}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					手机号：{{orderDetail.userInfo.userMobile}}
				</el-col>
				<el-col class="msgItem" :span="6">
					订单状态：{{$public.getMatchingItem(stateList,'value',orderDetail.orderState).label}}
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					创建人：{{orderDetail.operationInfo.create.handleUserName}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					创建日期：{{$public.FTime(orderDetail.operationInfo.create.handleTimeStamp,"YYYY-MM-DD")}}
				</el-col>
				<el-col class="msgItem" :span="6">
					充值金额：{{orderDetail.totalPrice}}
				</el-col>
				<el-col class="msgItem" :span="6">
					赠送金额：{{orderDetail.giveMoney}}
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					结算方式：
					<span v-if="!$public.isNull(orderDetail.payInfo)">
						<span v-for="(item,index) in orderDetail.payInfo.payList" :key="index">
							<span >
								{{$public.getMatchingItem(paymentList,'value',item.payType).label}}
								{{item.money}}
							</span>
						</span>
					</span>
				</el-col>
				<el-col class="msgItem" :span="6">
					结算备注：
					<span v-if="!$public.isNull(orderDetail.payInfo)">
						{{orderDetail.payInfo.payRemark}}
					</span>
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					销售人员：
					<span v-for="(item,index) in orderDetail.salesList" :key="index"> {{item.name}} </span>
					<img class="editImg" v-if="$buttonAuthority('order-rechargeOrderList-see-editStaff')" @click="open_dialogChangeStaffs(orderDetail)" src="../../../assets/img/shouyin/edit.png"/>
				</el-col>
				<el-col class="msgItem" :span="6">
					订单备注：{{orderDetail.remark}} 
				</el-col>
			</el-row>
		</div>
		<div v-if="dialogChangeStaffs_state">
			<dialogChangeStaffs :salesList="curRow.salesList" @submit="submit_dialogChangeStaffs"></dialogChangeStaffs>
		</div>
	</div>
</template>
<script>
	import dialogChangeStaffs from "../dialogChangeStaffs.vue"
	export default {
		components: {dialogChangeStaffs},
		data() {
			return {
				orderDetail:{
					userInfo:{},
					payInfo:{},
					operationInfo:{create:{}},
					orderItemList:[],
				},
				// 修改销售
				dialogChangeStaffs_state:false,
				curRow:{},
			}
		},
		computed:{
			stateList(){
				let list = this.$public.getRechargeOrderStateList()
				list.unshift({label:"全部",value:-1})
				return list
			},
			paymentList(){
				let list = this.$public.getPaymentList()
				list.unshift({label:"全部",value:-1})
				return list
			},
		},
		mounted() {
			this.getDetail();
		},
		methods: {
			// 获取用户详情
			getDetail(){
				let params = {
					orderNo:this.$route.query.no
				}
				let loading = this.$loading()
				this.$http.get("/order/OrderRecharge/GetInfo",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.orderDetail = res.data
					}
				})
			},
			// 修改销售人员
			open_dialogChangeStaffs(row){
				this.curRow = row;
				this.dialogChangeStaffs_state = true
			},
			submit_dialogChangeStaffs(obj){
				this.dialogChangeStaffs_state = false;
				if(obj.type==="success"){
					let params = {
						"orderNo": this.orderDetail.orderNo,
						"salesList": obj.data
					}
					let loading = this.$loading()
					this.$http.post("/order/OrderRecharge/UpdateSaleManMore",params).then(res=>{
						loading.close()
						if(res.code===0){
							this.$message({
								message: '修改成功',
								type: 'success'
							})
							this.getDetail();
						}
					})
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		.tit{
			padding:32px 20px 8px;display: flex;justify-content: space-between;
			.titL{
				font-size: 16px;color:$fontColor;background: url(../../../assets/img/cangku/detailName.png) 2px 8px no-repeat;background-size: 24px 24px;
				.titText{
					padding-left:12px;display:inline-block;line-height: 40px;
				}
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.msgBox{
			margin:0 20px;background-color: #FFFCF8;padding:20px 30px;box-sizing: border-box;border-radius: 20px;margin-top:30px;
			.msgItem{
				font-size: 14px;color:$fontColor;line-height: 40px;
				.sexImg{
					width:20px;height: 20px;vertical-align: middle;
				}
			}
		}
		.editImg{
			width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
		}
	}
</style>
